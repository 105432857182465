@import './vars.scss';

.content {
  margin-left: auto;
  margin-right: auto;

  @media (min-width: $min-desktop-width) {
    margin-top: 16px;
  }

  h4 {
    color: $color-header;
    color: $color-green-dark;
    margin-bottom: -4px;

    @media (max-width: $max-mobile-width) {
      margin: 16px;
      margin-top: 32px;
    }
  }

  li {
    margin: 8px 0;
    color: $color-gray-light;
  }
}

.column {
  @media (min-width: $min-desktop-width) {
    // max-width: $column-width;
  }

  // display: inline-block;
}

h3 {
  font-size: 32px;
  color: $color-gray-light;
  margin: 0;
  margin-bottom: 4px;
}

.page-link-separator {
  height: 1px;
  background: $color-blue-dark;
  margin-top: 16px;
  margin-bottom: 16px;
  opacity: 0.2;

  @media (max-width: $max-mobile-width) {
    opacity: 0.3;
    width: 75%;
    margin-right: auto;
    margin-left: auto;
  }
}

.page-link-top-margin {
  margin-top: 16px;
}

.page-header-image {
  img {
    width: 100%;
    height: 100%;
  }

  width: $column-width;
  margin: auto;

  @media (max-width: $max-mobile-width) {
    width: 100%;
  }
}

@media (max-width: $max-mobile-width) {
  h3 {
    font-size: 24px;
  }
}
