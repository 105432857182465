@import './../vars.scss';

.section-link {
  font-weight: 700;
  text-decoration: none;
  display: flex;
  align-items: center;
  flex-direction: row;
  transition: background-color 0.5s ease-in-out;
  position: relative;
  margin-bottom: 8px;
  
  &.back-red:hover {
    background: $color-red-light;
  }

  &.back-green:hover {
    background: $color-green-light;
  }

  &.back-blue:hover {
    background: $color-blue-light;
  }

  &.back-yellow:hover {
    background: $color-yellow-light;
  }

  &.back-gray:hover {
    background: $color-gray-light-transparent;
  }

  @media (max-width: $max-mobile-width) {
    .title-container {
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        width: 100%;
    }

    .title-separate {
      display: none;
    }
  }

  @media (min-width: $min-desktop-width) {
    .title-container {
      display: none;
    }

    .title-separate {
      display: unset;
    }
  }

  @media (max-width: $max-mobile-width) {
    flex-direction: column;
    align-items: initial;
  }

  @media (min-width: $min-desktop-width) {
    &.reverse {
      flex-direction: row-reverse;

      h2 {
        left: 0;
      }
    }
  }

  .image-container {
    width: 60%;
    position: relative;
    margin-bottom: -3px;

    @media (max-width: $max-mobile-width) {
      width: 100%;
    }
  
    img {
      width: 100%;
    }
  }

  h2 {
    position: absolute;
    bottom: 0;
    margin: 0;
    color: white;
    margin: 8px;
    font-size: $font-size-medium;
    right: 0;

    @media (max-width: $max-mobile-width) {
      top: unset;
      position: unset;
      width: 100%;
      text-align: center;
      margin: 0;
      bottom: unset;
    }

    p {
      margin: 0;
    }
  }

  @media (max-width: $max-mobile-width) {
    .gradient-background {
      xbackground: linear-gradient(to bottom, rgba(0,0,0,0.4) 0%,rgba(0,0,0,0) 50%,rgba(0,0,0,0.7) 100%);
      xbackground: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.2) 50%,rgba(0,0,0,0) 100%);
      background: radial-gradient(ellipse at center, rgba(0,0,0,0.5) 0%,rgba(0,0,0,0) 70%, rgba(0,0,0,0) 100%);
      position: absolute;
      height: 100%;
      width: 100%;
    }
  }

  h1 {
    font-size: 50px;
    width: 39%;
    margin: 0;
    text-align: center;
    opacity: 0.75;

    @media (max-width: $max-mobile-width) {
      color: white !important;
      opacity: 1;
      font-size: 40px;
      text-align: center;
      width: 100%;
    }
  }

  .lines {
    line-height: 22px;

    @media (max-width: $max-mobile-width) {
      display: none;
    }
  }

  .subtitle {
    @media (min-width: $min-desktop-width) {
      display: none;
    }
  }
}