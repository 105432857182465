@import './vars.scss';

.call-to-action {

  text-align: center;
  margin: 64px;

  a {
    padding: 8px;
    text-decoration: none;
    color: $color-red-dark;
    border: 1px solid $color-red-dark;
    font-size: $font-size-medium;
    transition: background-color 0.3s ease-in-out;

    &:hover {
      background: $color-red-light;
    }
  }
}

.service .call-to-action {
  margin: 32px;
}