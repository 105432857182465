@import './vars.scss';

.copyright {
  color: $color-text;
  font-size: $font-size-small;
  text-align: center;
  margin-top: 24px;
  margin-bottom: 12px;
  font-family: $font-secondary;
  font-style: normal;

  a {
    text-decoration: none;
    color: $color-blue-dark;
  }
}

.copyright-links {
  margin-top: 0;
}
