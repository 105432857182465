@import './../vars.scss';

.the-method {

  .method-separator {
    height: 1px;
    background: $color-yellow-dark;
    margin-top: 16px;
    margin-bottom: 64px;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
  }  
}