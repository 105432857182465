@import './../vars.scss';

form {
  font-family: $font-secondary;
  font-style: normal;

  @media (max-width: $max-mobile-width) {
    padding: 0 18px;
  }

  .input {
    margin: auto;
    margin-bottom: 16px;

    input, textarea {
      width: 100%;
      padding: 8px;
      margin-top: 4px;
      border: 1px solid $color-green-dark-transparent;
      color: black;
      box-sizing: border-box;
      font-weight: normal;

      &[type='submit'] {
        cursor: pointer;
        color: $color-blue-dark;
        border: 1px solid $color-blue-dark;
        font-size: $font-size-medium;
        text-align: left;
        margin: 0;
        padding: 8px 48px;
        width: auto;
        transition: background-color 0.3s ease-in-out;
        background: transparent;

        &:hover {
          background: $color-blue-light;
        }
      }
    }

    textarea {
      resize: none;
    }
  }
}

.contact-intro {
  margin-bottom: 48px;

  @media (max-width: $max-mobile-width) {
    margin-left: 16px;
  }
}

#form-send {
  text-align: center;
}

.form-column {
  @media (min-width: $min-desktop-width) {
    max-width: $form-width;
    display: block;
  }
}
