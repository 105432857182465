@charset "UTF-8";
.testimonials {
  margin: auto; }
  .testimonials .testimonial {
    margin-top: 32px;
    position: relative; }
    @media (max-width: 991px) {
      .testimonials .testimonial {
        margin-left: 16px; } }
  .testimonials img.page-image {
    margin: auto;
    display: block;
    width: 100%; }
  .testimonials .testimonial-separator {
    height: 1px;
    background: #fdf2f5;
    margin-left: 100px;
    margin-right: 100px;
    margin-top: 32px;
    margin-bottom: 32px; }
  .testimonials blockquote {
    font-style: italic;
    line-height: 32px;
    color: #7c7c7c; }
  .testimonials .testimonial:before {
    background-image: url("./../images/foot-red.png");
    background-repeat: no-repeat;
    background-size: contain;
    width: 48px;
    height: 48px;
    content: " ";
    color: #7bc65d;
    font-family: Georgia, serif;
    font-size: 60px;
    font-weight: bold;
    position: absolute;
    top: -10px; }
  .testimonials .testimonial-container:nth-child(2n) .testimonial:before {
    background-image: url("./../images/foot-green.png");
    color: #fe4e80; }
  .testimonials .testimonial-container:nth-child(3n) .testimonial:before {
    background-image: url("./../images/foot-blue.png");
    color: #19c0f1; }
  .testimonials .testimonial-container:nth-child(4n) .testimonial:before {
    background-image: url("./../images/foot-yellow.png");
    color: #ffc74f; }
  .testimonials .testimonial-name {
    margin-top: 28px;
    margin-left: 58px;
    font-style: italic; }
    .testimonials .testimonial-name::before {
      content: "• "; }
