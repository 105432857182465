@import './vars.scss';

$color-hamburger: white;
$color-hamburger-background: #66666650;

header {
  display: flex;
  flex-direction: column;

  a {
    text-decoration: none;
  }

  .logo {
    background-image: url('./images/logo_feet_625x242.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: 50%;
    height: 150px;
    margin-top: 16px;

    @media (max-width: $max-mobile-width) {
      display: none;
    }
  }

  header label:hover,
  header label:focus {
    background-color: #eee;
  }

  nav {
    z-index: 1;
    max-height: 0;
    margin: 0;
    overflow: hidden;
    clear: both;
    transition: max-height .3s cubic-bezier(0.63, 0, 0.25, 1);
    font-family: $font-secondary;
    font-style: normal;

    &.expanded {
      max-height: none;
      padding-top: 60px;
    }
  }

  ul {
    margin-top: 32px;
    display: flex;
    flex-direction: row;
    list-style: none;
    justify-content: center;
    padding-inline-start: 0;

    li > a {
      padding: 16px;
      letter-spacing: 1px;
      text-decoration: none;
      color: $color-blue-dark;
      transition: color 0.5s ease;

      &:hover {
        color: $color-red-dark;
      }
    
      &.active {
        // color: $color-red-dark;

        &:hover {
          color: $color-red-dark;
        }
      }
    }
  }

  #hamburger {
    height: 20px;
    width: 20px;
    padding: 20px;
    float: right;
    cursor: pointer;
    position: absolute;
    z-index: 2;
    margin: 4px;

    &:before {
      content: "";
      display: block;
      background-color: $color-leg-yellow;
      width: 100%;
      height: 3px;
    }
    
    &:after {
      content: "";
      display: block;
      background-color: $color-leg-blue;
      width: 100%;
      height: 3px;
      margin-top: 5px;
      box-shadow: 0px 8px 0 $color-leg-red;
    }
  }

  // desktop
  @media (min-width: $min-desktop-width) {
    nav {
      max-height: none;
    }

    #hamburger {
      display: none;
    }
  }
  
  // mobile
  @media (max-width: $max-mobile-width) {
    #hamburger-background {
      width: 100%;
      height: 100%;
      position: absolute;
      background: #333333cc;
      z-index: 1;
      display: none;

      &.expanded {
        display: initial;
      }
    }

    nav {
      background: #fafafa;
      position: absolute;
      width: 100%;
    }

    ul {
      flex-direction: column;
      margin: 6px 0;

      li {
        padding: 16px 0;
      }
    }
  }

  #nav-toggle {
    position: absolute;
    top: -100%;
    left: -100%;
  }
}
