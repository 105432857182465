@import './../vars.scss';

.page-link {
  text-decoration: none;
  display: flex;
  flex-direction: row;
  transition: background-color 0.5s ease-in-out;

  .img-container {
    position: relative;
    margin-right: 8px;
    width: 20%;
  }

  .img-bw {
    @media (max-width: $max-mobile-width) {
      opacity: 0;
    }
  }

  .img-over {
    position: absolute;
    left: 0;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  
    @media (max-width: $max-mobile-width) {
      opacity: 1;
    }
  }

  &:hover {
    .img-over {
      opacity: 1;
    }
  }
  
  .page-link-text {
    margin-top: 4px;
  }

  img {
    width: 100%;
    height: 100%;
  }

  h1 {
    transition: color 0.5s ease-in-out;
    margin: 0;
    color: $color-green-dark;
    margin-bottom: 4px;
  }

  h2 {
    margin: 0;
    color: $color-gray-light;
  }

  @media (max-width: $max-mobile-width) {
    .img-container {
      width: 30%;
    }

    .page-link-text {
      width: 60%;
    }  
  }
}