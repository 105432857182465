@import './vars.scss';
@import url('https://fonts.googleapis.com/css?family=Lora:400,400italic');
@import url('https://fonts.googleapis.com/css?family=Mukta&display=swap');

body {
  margin: auto;
  font-family: $font-primary;
  font-style: italic;
  -webkit-font-smoothing: antialiased;
  color: $color-text;
}

* {
  font-weight: 100;
}

#root {
  max-width: $min-desktop-width;
  margin: auto;
}

h1 {
  line-height: 1em;
  font-size: $font-size-large;

  @media (max-width: $max-mobile-width) {
    font-size: $font-size-medium;
  }
}

h2, h4 {
  font-size: $font-size-medium;

  @media (max-width: $max-mobile-width) {
    font-size: $font-size-medium-smaller;
  }
}

h1, h2, h3, h4 {
  font-family: $font-secondary;
  font-style: normal;
}

.alt-font {
  font-family: $font-secondary;
  font-style: normal;
}

.primary-font {
  font-family: $font-primary;
  font-style: italic;
}