@import './../vars.scss';

.services {
  margin: 0 80px 12px 80px;
  
  h4 {
    margin: 4px 0;
  }

  .service-header {
    margin: 12px 0;

    div {
      color: $color-header;
      font-size: $font-size-medium;
    }
  }
  
  .service-intro {
    margin: 12px 0;
  }

  h2 {
    margin-top: 4px;
  }

  p {
    margin: 8px 0;
  }

  .service {
    margin: 56px 0;
    border: 1px solid $color-blue-dark;
    font-family: $font-secondary;
    font-style: normal;

    @media (max-width: $max-mobile-width) {
      margin: 56px 24px;
    }  

    p {
      @media (max-width: $max-mobile-width) {
        margin: 8px 0;
      }
    }
  }

  .service-title {
    background: $color-blue-light;
    color: $color-blue-dark;
    padding: 8px;
    text-align: center;
  }

  .service-name {
    font-weight: bold;
  }

  .service-content {
    padding-top: 12px;
    padding-left: 12px;
    padding-right: 12px;

    ul, ol {
      margin-left: -10px;
    }
  }

  .prices p {
    color: $color-green-dark;
  }

  .duration {
    color: $color-blue-dark;
  }

  .line {
    height: 1px;
    background: $color-blue-dark;
  }

  .testimonials-link-container {
    margin-left: 24px;
    margin-top: 38px;
    display: flex;
    align-items: center;

    .foot {
      width: 24px;
      margin-right: 4px;
    }

    .testimonials-link {
      font-family: $font-secondary;
      font-style: normal;
      font-size: $font-size-medium;
      margin-left: 12px;
      letter-spacing: 1px;
      text-decoration: none;
      color: $color-blue-dark;
      transition: color 0.5s ease;
  
      &:hover {
        color: $color-red-dark;
      }
    }
  }
}

