$min-desktop-width: 992px;
$max-mobile-width: 991px;
$column-width: 934px;
$form-width: 500px;

$font-primary: 'Lora', serif;
$font-secondary: 'Mukta', sans-serif;

$font-size-small: 14px;
$font-size-medium-smaller: 16px;
$font-size-medium: 18px;
$font-size-large: 22px;

$color-green-light: #f2f8ee;
$color-green-dark: #7bc65d;
$color-green-dark-transparent: #7bc65d80;

$color-blue-light: #eff9fc;
$color-blue-dark: #19c0f1;

$color-red-light: #fdf2f5;
$color-red-dark: #fe4e80;
$color-red-dark-transparent: #fe4e8030;

$color-yellow-light: #fffceb;
$color-yellow-dark: #ffc74f;

$color-gray-light: #7c7c7c;
$color-gray-light-transparent: #7c7c7c10;
$color-gray-dark: #333;

$color-leg-yellow: $color-yellow-dark;
$color-leg-blue: $color-blue-dark;
$color-leg-red: $color-red-dark;

$color-text: $color-gray-light;
$color-header: $color-gray-dark;
