@import './../vars.scss';

.service-link {
  text-align: center;
  margin-bottom: 64px;

  h2 {
    font-size: $font-size-medium;
  }

  p {
    margin: 8px 0;
  }
}