@import './../vars.scss';

.page {
  margin: auto;
  position: relative;

  h1, h2 {
    margin: 0;
    padding-left: 16px;
    padding-right: 16px;
    text-align: center;
  }

  h1 {
    top: 0;
    color: $color-green-dark;
    background: $color-green-light;
    padding-top: 8px;
    padding-bottom: 8px;
  }

  h2 {
    padding-top: 10px;
    padding-bottom: 10px;
    color: $color-blue-dark;
    background: $color-blue-light;
  }

  img.page-image {
    margin: auto;
    display: block;
    width: 100%;
  }

  p {
    font-size: $font-size-medium-smaller;
    margin: 12px auto;
    line-height: 30px;
    color: $color-gray-light;

    @media (max-width: $max-mobile-width) {
      margin: 16px;
    }
  }

  li {
    line-height: 30px;
    font-size: $font-size-medium-smaller;
  }

  .next-milestone-title {
    margin-bottom: 16px;
  }

  .next-milestone {

    h1, h2 {
      text-align: unset;
      background: none;
      padding: 4px 8px;
    }

    h1 {
      color: $color-green-dark;
    }

    h2 {
      color: $color-gray-dark;
    }

    .img-container {
      margin: 0;
      width: 50%;
    }

    img {
      height: auto;
    }

    .page-link-text {
      margin-top: 0;

      @media (max-width: $max-mobile-width) {
        width: 100%;
      }
    }
  }
}