@import './../vars.scss';

.links {
  @media (max-width: $max-mobile-width) {
    margin: 16px;
  }
}

.feet {
  background-image: url('./../images/feet.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: 50%;
  height: 120px;
  margin-top: 48px;
}